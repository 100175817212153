import style from "./About.module.scss";
import React from "react";
import SkillsTable from "components/SkillsTable/SkillsTable";

function About() {
    return (
        <div className={style.aboutPage}>
            <div className={style.main}>
                <div className={style.myself}>
                    <span>
                        My name is Etienne Schneider. I am a 23-year-old French
                        developer graduated with a master's degree in computer
                        and cognitve science passionate about web, blockchain
                        and decentralized finance. My curiosity drives me to
                        understand the world around me, which explains my strong
                        interest in economics, history, and psychology. <br />
                        Crypto enthusiast.
                    </span>
                </div>

                <div className={style.skills}>
                    <SkillsTable />
                </div>
            </div>
        </div>
    );
}

export default About;
