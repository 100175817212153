import { createContext, useContext, useState } from "react";
export const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState("Home")

  const AppContextValues = {
    currentPage,
    setCurrentPage,
  };

  return (
    <AppContext.Provider value={AppContextValues}>
      {" "}
      {children}{" "}
    </AppContext.Provider>
  );
};
