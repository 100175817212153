import React, { useState, useEffect } from "react";
import style from "./WelcomePage.module.scss";

function WelcomePage({ onWelcome }) {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHidden(true);
      // Appeler la fonction de rappel onWelcome lorsque l'accueil est terminé
      onWelcome();
    }, 2400);

    return () => clearTimeout(timer);
  }, [onWelcome]);

  return (
    <div className={`${style.welcomePage} ${hidden ? style.hidden : ""}`}>
      <h1 className={`${style.trackingInContract}`}>
        Welcome.
      </h1>
    </div>
  );
}

export default WelcomePage;
