import "./HeaderBar.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Linkedin } from "assets/icons/linkedin.svg";
import { ReactComponent as NewWindow } from "assets/icons/newWindow.svg";
import { ReactComponent as Burger } from "assets/icons/burger-menu.svg";
import { ReactComponent as Cross } from "assets/icons/cross.svg";
import { useState } from "react";
import { useAppContext } from "contexts/AppContext";
import classNames from "classnames";

function HeaderBar() {
    const [menuOpened, setMenuOpened] = useState(false);
    const { currentPage, setCurrentPage } = useAppContext();

    const toggleMenuOpened = () => {
        setMenuOpened(prevState => !prevState);
    };

    const pages = [
        { title: "Home", url: "/" },
        { title: "About", url: "/about" },
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
        toggleMenuOpened(); // Ferme le menu mobile après la sélection d'une page
    };

    const activePage = currentPage;

    return (
        <div className={classNames("headerBar", { mobileOpened: menuOpened })}>
            <span>Etix.</span>

            <ul className="menu">
                {pages.map((page, index) => (
                    <li
                        key={index}
                        className={classNames({ active: activePage === page.title })}
                        onClick={() => handlePageChange(page.title)}
                    >
                        <Link to={page.url}>{page.title}</Link>
                    </li>
                ))}

                <li>
                    <a
                        href="pdf/cv.pdf"
                        className="contact"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        My resume
                        <NewWindow />
                    </a>
                </li>

                <li>
                    <a
                        href="mailto:etix.business@gmail.com"
                        className="contact"
                    >
                        Contact
                        <NewWindow />
                    </a>
                </li>

                <a
                    href="https://www.linkedin.com/in/etienne-schneider-009a39196/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Linkedin />
                </a>
            </ul>

            <button className="burgerBtn" onClick={toggleMenuOpened}>
                {menuOpened ? <Cross /> : <Burger />}
            </button>

            {menuOpened && (
                <div className="menuMobile">
                    <ul>
                        {pages.map((page, index) => (
                            <li
                                key={index}
                                className={classNames({ active: activePage === page.title })}
                                onClick={() => handlePageChange(page.title)}
                            >
                                <Link to={page.url}>{page.title}</Link>
                            </li>
                        ))}

                        <li>
                            <a
                                href="pdf/cv.pdf"
                                className="contact"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                My resume
                                <NewWindow />
                            </a>
                        </li>

                        <li>
                            <a
                                href="mailto:etix.business@gmail.com"
                                className="contact"
                            >
                                Contact
                                <NewWindow />
                            </a>
                        </li>

                        <a
                            href="https://www.linkedin.com/in/etienne-schneider-009a39196/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Linkedin />
                        </a>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default HeaderBar;
