import style from "./SkillsTable.module.scss";
import skills from "skills.json";
import SkillCard from "components/SkillCard/SkillCard";

function SkillsTable() {
  return (
    <div className={style.skillsTable}>
      {skills.map((domain, index) => (
        <div key={index} className={style.domain}>
          <h2>({domain.domain})</h2>
          <ul>
            {domain.skills.map((skill, subIndex) => (
              <li key={subIndex}>
                <SkillCard skill={skill} />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default SkillsTable;
