import style from './Footer.module.scss';

function Footer() {
    return (
        <footer className={style.footer}>
            <span>
                <a href={'mailto: etix.business@gmail.com'}>
                    etix.business@gmail.com
                </a>
            </span>
            <span>&copy; 2024 Etienne Schneider. All rights reserved.</span>
        </footer>
    )
}

export default Footer;