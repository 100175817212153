import "./Menu.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Linkedin } from "assets/icons/linkedin.svg";
import { ReactComponent as NewWindow } from "assets/icons/newWindow.svg";
import { useAppContext } from "contexts/AppContext";
import classNames from "classnames";

const pages = [
    {
        title: "Home",
        url: "/",
    },
    {
        title: "About",
        url: "/about",
    },
];

function Menu() {
    const { setCurrentPage, currentPage } = useAppContext();

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    return (
        <ul className="menu">
            {pages.map((page, index) => (
                <li
                    key={index}
                    onClick={() => {
                        handleChangePage(page.title);
                    }}
                >
                    <Link
                        to={page.url}
                        className={classNames({
                            active: page.title === currentPage,
                        })}
                    >
                        {page.title}
                    </Link>
                </li>
            ))}

            <li>
                <a
                    href="pdf/cv.pdf"
                    className="contact"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    My resume
                    <NewWindow />
                </a>
            </li>

            <li>
                <a
                    href={"mailto: etix.business@gmail.com"}
                    className="contact"
                >
                    Contact
                    <NewWindow />
                </a>
            </li>

            <a
                href="https://www.linkedin.com/in/etienne-schneider-009a39196/"
                target="_blank"
                rel="noreferrer"
            >
                <Linkedin />
            </a>
        </ul>
    );
}

export default Menu;
