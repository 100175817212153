import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "pages/Home/Home";
import HeaderBar from "./components/HeaderBar/HeaderBar";
import WelcomePage from "components/WelcomePage/WelcomePage";
import About from "pages/About/About";
import Footer from "components/Footer/Footer";
import { AppContextProvider } from "contexts/AppContext";
import { useEffect, useState } from "react";
import Menu from "components/Menu/Menu";

function Content({ loading }) {
    const [welcomed, setWelcomed] = useState(false);

    useEffect(() => {
        const isWelcomed = localStorage.getItem("welcomed");
        if (isWelcomed === "true") {
            setWelcomed(true);
        }
    }, []);

    const handleWelcome = () => {
        localStorage.setItem("welcomed", "true");
        setWelcomed(true);
    };

    if (loading && !welcomed) {
        return <WelcomePage onWelcome={handleWelcome} />;
    }

    return (
        <Router>
            <HeaderBar />
            <div className="page">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                </Routes>
                <div className="rightPart">
                    <Menu />
                </div>
            </div>
            <Footer />
        </Router>
    );
}

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2600);

        return () => clearTimeout(timer);
    }, []);

    return (
        <AppContextProvider>
            <Content loading={loading} />
        </AppContextProvider>
    );
}

export default App;
