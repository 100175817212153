import style from './SkillCard.module.scss';
import SkillIcon from 'components/SkillIcon/SkillIcon';

function SkillCard({skill}) {
    return (
        <div className={style.skillCard}>
            <SkillIcon icon={skill.icon}/>
            <span>{skill.name}</span>
        </div>
    )
}

export default SkillCard;