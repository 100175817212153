import style from "./Home.module.scss";
import ConnectButton from "components/Buttons/Button";

function Home() {
    return (
        <div className={style.homePage}>
            <div className={style.main}>
                <div className={style.titleSection}>
                    <h1>Etienne Schneider</h1>
                    <span>Web development & DevOps</span>
                </div>

                <div className={style.content}>
                    <span>
                        As a computer science apprentice, I'm delving into the
                        realms of development, DevOps, and blockchain
                        technology, blending theory with hands-on experience
                    </span>
                    <ConnectButton rightImage={true}>
                        <a
                            href="https://www.linkedin.com/in/etienne-schneider-009a39196/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Let's connect
                        </a>
                    </ConnectButton>
                </div>
            </div>
        </div>
    );
}

export default Home;
