import "./Buttons.module.scss";
import { ReactComponent as Arrow } from "assets/icons/rightArrow.svg";

function ConnectButton({ children, rightImage }) {
  return (
    <button data-role="primary">
      {children} 
      {rightImage && <Arrow className="arrow-icon" />}
    </button>
  );
}

export default ConnectButton;
